import * as React from "react";
import { useState, useEffect } from 'react';
import Header from './Header.js';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;

const initialDataset = [];

const chartSetting = {
  xAxis: [
    {
      label: 'Message Counts',
      tickMinStep: 1,
    },
  ],
  width: 1300,
  height: 700,
  margin: { left: 200 },
};

const valueFormatter = (value) => `${value}`;

const CustomerMsgDashboard = (props) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredDataset, setFilteredDataset] = useState(initialDataset);
  const [datetimeArray, setDateTimeArray] = useState(['day', 'month', 'year']);

  const handleDateChange = async () => {
    let url = URL + '/kwiqreply/analytics/message-counts/';
    let params = {};

    // If both dates are selected, include them in the parameters
    if (fromDate && toDate && (fromDate.isBefore(toDate) || fromDate.isSame(toDate))) {
      params = {
        from_date: fromDate.format('YYYY-MM-DD'),
        to_date: toDate.format('YYYY-MM-DD'),
      };
    }

    try {
      const response = await axios.get(url, { params });
      if (response.data && Array.isArray(response.data)) {
        setFilteredDataset(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePhoneFilter = async (phoneNo) => {
    let url = URL + '/kwiqreply/analytics/message-counts/';
    let params = { phone_no: phoneNo };

    try {
      const response = await axios.get(url, { params });
      if (response.data && Array.isArray(response.data)) {
        setFilteredDataset(response.data);
      } else {
        console.error('Invalid data received from server:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    handleDateChange();
  }, [fromDate, toDate]);

  const handlePhoneInputChange = (event) => {
    const phoneNo = event.target.value;
    if (phoneNo && phoneNo.trim() !== '') {
      handlePhoneFilter(phoneNo.trim());
    } else {
      handleDateChange(); // Reset to default data if phone number input is empty
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePhoneInputChange(event);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <Header userState={props.userState} />
        <Container maxWidth={false}>
          <h2 className="heading-title">Message Count By Customer</h2>
          <Grid container spacing={3} sx={{ marginTop: 3 }}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="div">
                    Search By Phone Number
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <TextField
                      id="phone_no"
                      name="phone_no"
                      label="Enter Phone No"
                      fullWidth
                      onKeyPress={handleKeyPress}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="div">
                    Select Date Range
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      label="From Date"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      disableFuture
                      views={datetimeArray}
                      sx={{ mr: 2 }}
                      label="DD/MM/YYYY"
                    />
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      disableFuture
                      views={datetimeArray}
                      label="DD/MM/YYYY"
                    />
                    </LocalizationProvider>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ marginTop: 3 }}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="div">
                    Frequently Asked Questions
                  </Typography>
                  <BarChart
                    dataset={filteredDataset}
                    yAxis={[{ scaleType: 'band', dataKey: 'message' }]}
                    series={[{ dataKey: 'count', label: `Message Counts`, valueFormatter }]}
                    layout="horizontal"
                    grid={{ vertical: true }}
                    borderRadius={5}
                    {...chartSetting}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </LocalizationProvider>
  );
}

export default CustomerMsgDashboard;
